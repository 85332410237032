@import styles/variables
@import styles/extends

.button
    width: 100%
    height: 64px
    border-radius: 70px
    font-size: 20px
    color: white
    background-image: linear-gradient(to left, $green-blue, $gradient-end)
    box-shadow: 0 8px 20px rgba($green-blue2, 0.3)
    border: 0

    +flexCenter
