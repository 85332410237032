@import styles/variables
@import styles/extends

.png
    width: 20px
    position: absolute
    top: 14px
    right: 14px

    path
        fill: white

.heading
    margin: 40px auto 90px
    padding: 0 65px
    text-align: center

    .title
        font-weight: bold
        font-size: 24px

    .sub-title
        margin-top: 8px
        font-size: 20px

    p
        margin-top: 20px
        font-size: 16px

    +mobileView320
        padding: 0 40px

.mentos
    width: 120px
    height: 120px
    position: absolute
    top: -60px
    left: calc(50% - 60px)
    transform: scale(0)
    will-change: transform
    pointer-events: none

    &.ready
        transform: scale(1)
        animation: lightningAnimation 1.5s

    &.animate
        transform: scale(1)
        animation: breathAnimation 3s infinite

    .mentos-error
        width: 100%
        height: 100%
        border-radius: 50%
        box-shadow: 0 8px 20px rgb($red, 0.3)
        overflow: hidden

    .mentos-video
        width: 100%
        height: 100%
        border-radius: 50%
        overflow: hidden
        box-shadow: 0 8px 20px rgb($green-blue2, 0.3)
        transform: translate3d(0, 0, 0)
        backface-visibility: hidden

        video
            width: 100%
            height: auto
            transform: scale(1.05)
            transform-origin: center

    .check
        position: absolute
        bottom: -28px
        right: -25px
        width: 85px

.detail
    position: relative
    background: white
    border-radius: 10px
    box-shadow: 0 4px 20px 0 rgba($black, 0.1)
    padding: 80px 24px 30px
    margin: 0 28px 30px

    .basic
        border: 1px solid $light-grey
        border-radius: 10px
        padding: 20px 0 25px
        text-align: center
        margin-bottom: 24px

    .code
        font-size: 24px
        margin-bottom: 16px

    .info
        +flexCenter

        & > div
            padding: 0 30px

            &:first-of-type
                border-right: 1px solid $light-grey

            +mobileView320
                padding: 0 25px

        .name
            font-size: 12px
            margin-bottom: 4px

        .value
            font-size: 16px

    .list
        .item
            +flexSpaceBetween

            font-size: 16px

            &:not(:last-of-type)
                margin-bottom: 16px

            &.amount .value
                color: $green-blue

        .more
            padding-left: 12px
            border-left: 1px solid $light-grey
            margin-bottom: 16px

    .hint
        +flexCenter

        margin-top: 24px
        padding: 0 8px

        img
            width: 48px
            margin-right: 10px

        span
            flex: 1

        +mobileView320
            padding: 0

.modify-email
    padding: 0 55px
    text-align: center
    margin-bottom: 40px

    span
        color: $green-blue

.button
    margin: 0 40px 80px

    .error
        font-size: 14px
        color: $red
        text-align: center
        margin-top: 10px

    +mobileView320
        margin: 0 28px 80px

.mask
    position: fixed
    top: 0
    left: 0
    height: calc(var(--vh,1vh) * 100)
    width: 100%
    background: rgba($black, 0.7)
    opacity: 0
    pointer-events: none
    z-index: 5

    +transition
    +flexCenter

    &.show
        +transition

        opacity: 1
        pointer-events: inherit

.dialog
    background: white
    text-align: center
    overflow: hidden
    border-radius: 4px
    width: 80%

    .title
        margin-top: 24px
        font-size: 20px

    input
        appearance: none
        margin: 15px 24px 30px
        width: calc(100% - 48px)
        height: 46px
        padding: 12px 16px
        border-radius: 4px
        border: 1px solid $light-grey
        caret-color: $green-blue
        outline: none

        &:focus
            border-color: $green-blue

        &.error
            border-color: $red
            margin-bottom: 0

            & + .error-message
                display: block

    .dialog-button
        background-image: linear-gradient(to left, $green-blue, $gradient-end)
        height: 60px
        width: 100%
        appearance: none
        font-size: 16px
        color: white
        border: 0

        +flexCenter

        &.disabled
            background: $light-grey2

        .loading
            display: inline-block
            position: relative
            width: 21px
            height: 21px

            & > div
                position: absolute
                top: 9.5px
                width: 4px
                height: 4px
                border-radius: 50%
                background: white
                animation-timing-function: cubic-bezier(0, 1, 1, 0)

                &:nth-child(1)
                    left: 2px
                    animation: loadingIcon1 0.55s infinite

                &:nth-child(2)
                    left: 2px
                    animation: loadingIcon2 0.55s infinite

                &:nth-child(3)
                    left: 9px
                    animation: loadingIcon2 0.55s infinite

                &:nth-child(4)
                    left: 15px
                    animation: loadingIcon3 0.55s infinite

    .error-message
        display: none
        text-align: left
        color: $red
        margin-bottom: 30px
        margin: 10px 24px 30px

.in-waiting
    position: absolute
    top: 0
    left: 0
    width: 100%

    .heading
        margin: 40px 0 0
        padding: 0 50px

    .image
        position: relative
        margin: 20px auto 25px
        width: 240px

        +flexCenter

        img
            margin-left: -10px

    .dots-group
        position: absolute
        top: 73px
        right: calc(50% - 60px)

        +flexCenter

        & > div
            margin: 0 2px
            background: white
            width: 8px
            height: 8px
            border-radius: 5px
            animation: dotFlashing 1.5s infinite linear alternate

            &:nth-of-type(2)
                animation-delay: -1s

            &:nth-of-type(3)
                animation-delay: -2s

    .refresh
        +flexCenter

        color: $green-blue
        transform: translateY(30px)
        opacity: 0

        img
            margin-right: 5px

        span
            color: $warm-grey

        div
            display: inline-flex
            align-items: center

        &.on
            transform: translateY(0)
            opacity: 1
            transition: 0.3s ease all

@keyframes dotFlashing
    0%
        opacity: 0.2

    20%
        opacity: 0.2

    40%
        opacity: 1

    60%
        opacity: 1

    80%
        opacity: 0.2

    100%
        opacity: 0.2

@keyframes lightningAnimation
    0%
        transform: scale(0)

    60%
        transform: scale(1.1)

    70%
        transform: scale(0.95)

    80%
        transform: scale(1.05)

    90%
        transform: scale(0.975)

    100%
        transform: scale(1)

@keyframes breathAnimation
    0%
        transform: scale(1)

    45%
        transform: scale(1.1)

    55%
        transform: scale(1.1)

    100%
        transform: scale(1)

@keyframes loadingIcon1
    0%
        transform: scale(0)

    100%
        transform: scale(1)

@keyframes loadingIcon3
    0%
        transform: scale(1)

    100%
        transform: scale(0)

@keyframes loadingIcon2
    0%
        transform: translate(0, 0)

    100%
        transform: translate(6px, 0)
