@import styles/variables
@import styles/extends

.error
    height: calc(var(--vh,1vh) * 100)
    width: 100%
    color: $warm-grey
    flex-direction: column

    +flexCenter

    img
        width: 60px
        height: auto

    .title
        margin: 16px 0 6px
        font-size: 24px

    .desc
        margin-bottom: 16px
        font-size: 16px
        text-align: center
        width: 80%

    a
        color: $green-blue
