@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;500;600&display=swap")

@import styles/variables
@import styles/extends

html,
body
    font-family: Barlow, PingFang TC, Noto Sans CJK TC, Noto Sans TC, Microsoft JhengHei, 微軟正黑體, helvetica, Arial, sans-serif
    touch-action: manipulation
    font-size: 14px
    width: 100%
    font-weight: normal
    color: $black
    line-height: normal
    -webkit-text-size-adjust: none
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    text-size-adjust: none

*
    box-sizing: border-box
    scrollbar-width: none

*::-webkit-scrollbar
    display: none

img
    display: block
    max-width: 100%

a
    text-decoration: none
    color: $green-blue

    &:hover
        text-decoration: none

p
    margin: 0

button,
[type='button'],
[type='reset'],
[type='submit']
    -webkit-appearance: none

div,
input,
textarea,
button,
select,
a
    -webkit-tap-highlight-color: transparent

    &:active,
    &:focus
        outline: none

h1,
h2,
h3,
h4,
h5,
h6
    margin: 0

ul,
li
    padding: 0
    margin: 0

li
    list-style-type: none

hr
    border-style: solid
    border-top: 0

// css variables
// iOS < 11.2
@supports (padding-bottom: constant(safe-area-inset-bottom))
    \:root
        --safeAreaInsetBottom: constant(safe-area-inset-bottom)

// iOS >= 11.2
@supports (padding-bottom: env(safe-area-inset-bottom))
    \:root
        --safeAreaInsetBottom: env(safe-area-inset-bottom)
