@import styles/variables
@import styles/extends

.wrapper
    +flexSpaceBetween

    flex-direction: column
    min-height: calc(var(--vh,1vh) * 100)

    &.green-blue
        background: $green-blue

    & > *
        width: 100%

    & > div
        flex: 1
        display: flex
        flex-direction: column
        position: relative

    footer
        z-index: 1
        background: $light-grey
        padding: 14px 0 calc(14px + var(--safeAreaInsetBottom))
        flex-direction: column
        font-size: 12px
        color: $warm-grey

        +flexCenter

        ul
            +flexCenter

            margin-bottom: 6px

        li
            padding: 0 10px

            &:not(:last-of-type)
                border-right: 1px solid rgba($black, 0.1)

        a
            color: $warm-grey

        div
            +flexCenter

        img
            width: 10px
            margin: 2px 3px 0 0

.wiggle
    position: fixed !important
    top: 0
    left: 0
    height: calc(var(--vh,1vh) * 100)
    width: 100%
    overflow: hidden
    z-index: 0 !important

.ball1, .ball2, .ball3
    position: absolute
    width: 300px
    height: 300px
    border-radius: 50%
    filter: blur(20px)
    opacity: 0.8

.ball1
    animation: wiggleAnimation1 5s infinite linear
    background: $blur1
    left: -80px
    top: -80px

.ball2
    background: $blur2
    animation: wiggleAnimation2 5s infinite linear
    right: -100px
    top: -40px

.ball3
    animation: wiggleAnimation3 5s infinite linear
    background: $blur3
    left: 30px
    top: 100px

@keyframes wiggleAnimation1
    0%
        transform: translate(0, 0)

    25%
        transform: translate(60px, 40px)

    55%
        transform: translate(40px, 80px)

    80%
        transform: translate(30px, 20px)

    100%
        transform: translate(0, 0)

@keyframes wiggleAnimation2
    0%
        transform: translate(0, 0)

    20%
        transform: translate(20px, 30px)

    40%
        transform: translate(0px, 50px)

    60%
        transform: translate(-20px, -40px)

    100%
        transform: translate(0, 0)

@keyframes wiggleAnimation3
    0%
        transform: translate(0, 0)

    33%
        transform: translate(-40px, -20px)

    66%
        transform: translate(80px, -40px)

    100%
        transform: translate(0, 0)
