@import variables

=hover
    @media not all and (pointer: coarse)
        &:hover
            @content

=fullHeight
    height: calc(var(--vh,1vh) * 100)

=flexCenter
    display: flex
    justify-content: center
    align-items: center

=flexStart
    display: flex
    justify-content: flex-start
    align-items: center

=flexSpaceBetween
    display: flex
    justify-content: space-between
    align-items: center

=transition
    transition: 0.3s ease all

=mobileView320
    @media (max-width: 320px)
        @content
