@import styles/variables
@import styles/extends

.entry
    padding: 40px 32px 0

    &.loaded
        .card
            transition: 0.5s ease all
            transform: translateY(0)
            opacity: 1

        .how-to-use
            transition: 0.3s ease all 0.1s
            transform: translateY(0)
            opacity: 1

.card
    width: 100%
    border-radius: 10px
    box-shadow: 0 4px 20px rgba($black, 0.1)
    padding: 40px 24px 24px
    background: white
    margin-bottom: 20px
    opacity: 0
    flex-direction: column
    transform: translateY(30px)

    +flexCenter

    & > *
        width: 100%

.logo
    +flexSpaceBetween

    margin-bottom: 26px
    width: 145px

    img
        width: calc(100% - 55px)

.info
    border: 1px solid $light-grey
    border-radius: 10px
    padding: 24px 20px

    &.disabled
        .heading
            margin: 0

    .heading
        +flexCenter
        flex-direction: column
        margin-bottom: 20px
        text-align: center

        & > span
            font-weight: 500
            font-size: 30px
            margin: 0 0 8px
            .text
                margin-bottom: 16px

    .item
        display: flex
        justify-content: space-between
        align-items: flex-start

        &:not(:first-of-type)
            margin-top: 20px

        .name
            flex: 1
            display: flex
            justify-content: center
            align-items: flex-start
            flex-direction: column

.hint
    font-size: 12px
    margin-top: 4px
    color: $warm-grey

.button
    margin: 20px 0

.agreement
    text-align: center
    font-size: 12px

.how-to-use
    text-align: center
    color: white
    margin-bottom: 40px
    transform: translateY(30px)
    opacity: 0

    +flexCenter

    img
        width: 16px
        margin-left: 4px

.message
    margin-top: 20px
    text-align: center

.mask
    height: 100%
    width: 100%
    position: fixed
    top: 0
    left: 0
    background: rgba($black, 0.7)
    opacity: 0
    pointer-events: none
    z-index: 5

    +transition

    &.show
        opacity: 1
        pointer-events: inherit

        +transition

        .manual
            +transition

            transform: translateY(0)

.manual
    position: absolute
    bottom: 0
    left: 0
    transform: translateY(50%)

    +transition

    .list
        display: flex
        justify-content: flex-start
        align-items: center

        li
            flex: 1 0 100vw

.content
    width: 100%
    background: white
    border-radius: 10px 10px 0 0
    overflow: hidden

.close
    width: 40px
    height: 40px
    position: absolute
    top: -45px
    right: 6px

    &:before, &:after
        content: ""
        position: absolute
        background: white
        left: 6px
        top: calc(50% - 1px)
        width: 25px
        height: 1px

    &:before
        transform: rotate(45deg)

    &:after
        transform: rotate(135deg)

.image
    width: 100%

    & > div
        padding-top: 55%
        background-position: center
        background-size: cover
        background-repeat: no-repeat

.text
    margin-top: 24px
    font-size: 16px
    text-align: center

.arrow
    position: absolute
    bottom: 55px
    opacity: 0
    pointer-events: none
    z-index: 1

    +transition

    &.show
        +transition

        opacity: 1
        pointer-events: inherit

    &.left
        left: 20px
        transform: rotate(180deg)

    &.right
        right: 20px

.number
    position: absolute
    top: 16px
    left: 16px
    height: 30px
    width: 30px
    box-shadow: 0 4px 20px 0 rgba($black, 0.1)
    z-index: 1
    background: white
    border-radius: 50%

    +flexCenter

.indicator
    margin: 24px 0
    padding-bottom: var(--safeAreaInsetBottom)

    li
        width: 5px
        height: 5px
        margin: 0 4px
        border-radius: 5px
        background: $light-grey

        &.active
            background: $black2

    +flexCenter

.pile-state
    height: 26px
    padding: 0 7px 0 16px
    border: 1px solid $light-grey
    border-radius: 4px
    position: relative
    line-height: 1

    +flexCenter

    span
        font-size: 12px

    &:before
        content: ""
        position: absolute
        height: 5px
        width: 5px
        border-radius: 5px
        top: 10px
        left: 7px
        background: $orange

    &.enabled:before
        background: $green

    &.charging:before
        background: $orange

    &.disabled:before
        background: $warm-grey
