.terms
    padding: 20px 25px
    font-size: 16px
    line-height: 1.5

    h1
        font-size: 2.5em

    h3
        font-size: 20px
        font-weight: bold
        margin-top: 25px

    h5
        font-size: 1.25em

    p
        margin-bottom: 1em

    ol
        list-style-type: decimal

        li
            list-style-type: inherit

    ol, ul
        padding: 0 0 0 40px
