.maintenance
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: calc(var(--vh, 1vh) * 100)
    background: white
    z-index: 10000
    display: flex
    justify-content: space-between
    align-items: center
    flex-direction: column
.main
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    max-width: 580px
    padding: 0 40px
    text-align: center
    h1
        margin: 16px 0 10px

.footer
    margin-bottom: 30px

