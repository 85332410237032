@import styles/variables
@import styles/extends

.loading
    +flexCenter
    +fullHeight

    flex-direction: column
    width: 80%
    margin: 0 auto
    color: $warm-grey
    font-size: 16px

    .lottie
        margin: 0 auto 12px
