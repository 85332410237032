$black: #111111
$black2: #333333
$grey: #999999
$light-grey: #eeeeee
$light-grey2: #cccccc
$warm-grey: #999999
$orange: #ffa111
$green: #42d354
$green-blue: #22d3d3
$green-blue2: #4EEBC5
$gradient-end: #39fa97
$bg-gradient-start: #9cffc4
$red: #FF3232
$blur1: #79FFCF
$blur2: #9CFFC4
$blur3: #9CFFFF
$blue: #007aff
