@import styles/variables
@import styles/extends

.navigation-map-dialog
    font-family: PingFang TC
    position: fixed
    bottom: 0
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: bottom
    opacity: 0
    transition: opacity 0.3s ease
    pointer-events: none
    z-index: 101
    width: 100%
    padding: 0 10px

    &.show
        transition: opacity 0.3s ease
        pointer-events: auto
        opacity: 1

        .mask
            opacity: 1
            transition: opacity 0.3s ease
            pointer-events: auto

        .direct
            transition: all 0.4s ease 0.05s
            transform: translateY(0)
            opacity: 1

        .cancel
            transition: all 0.4s ease 0.15s
            transform: translateY(0)
            opacity: 1

.mask
    position: fixed
    width: 100%
    height: 100%
    top: 0
    left: 0
    background: rgba(black, 0.7)
    opacity: 0
    transition: opacity 0.3s ease

.direct,
.cancel
    transition: all 0.3s ease
    transform: translateY(30px)
    opacity: 0
    z-index: 1
    position: relative
    border-radius: 13px
    background: white
    color: $blue
    text-align: center
    font-size: 18px

.direct
    overflow: hidden

    li
        padding: 15px 0

        &:not(:first-of-type)
            border-top: 1px solid $light-grey

.cancel
    margin: 5px 0 15px
    padding: 15px 0
    font-weight: bold
