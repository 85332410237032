.waiting-appear {
    opacity: 0;
    transform: translateY(30px);

}
.waiting-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease
}


.waiting-exit {
    opacity: 1;
}
.waiting-exit-active {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.3s
}

.charging-enter {
    opacity: 0;
    transform: translateY(30px);

}
.charging-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease
}
